import React, { Component } from 'react';
import './NavMenu.css';

export default class NavMenu extends Component {
 
  cerrarSesion(e){
    e.preventDefault();
    localStorage.removeItem('sesionAdmin');
    window.location.href = "/";
    
   }

  render() {
    return (
      <div class="vertical-nav bg-white" id="sidebar">
      <div class="py-4 px-3 mb-4 bg-light">
        <div class="media d-flex align-items-center"><img src="https://dolcesalato.com.pe/imagenes/logo.png" alt="..." width="65" class="mr-3 rounded-circle img-thumbnail shadow-sm" />
          <div class="media-body">
            <h4 class="m-0">Dolce Salato</h4>
            <p class="font-weight-light text-muted mb-0">Administrador</p>
          </div>
        </div>
      </div>

      <p class="text-gray font-weight-bold text-uppercase px-3 small pb-4 mb-0">Opciones</p>

      <ul class="nav flex-column bg-white mb-0">
        <li class="nav-item">
          <a href="/Producto" class="nav-link text-dark font-italic bg-light">
            <i class="fa fa-th-large mr-3 text-warning fa-fw"></i>
            Productos
          </a>
        </li>
        <li class="nav-item">
          <a href="/pedido" class="nav-link text-dark font-italic">
            <i class="fas fa-box mr-3 text-warning fa-fw"></i>
            Pedidos
          </a>
        </li>
        <li class="nav-item">
          <a href="/distritos" class="nav-link text-dark font-italic">
            <i class="fas fa-map-marked-alt mr-3 text-warning fa-fw"></i>
            Distritos
          </a>
        </li>
        <li class="nav-item">
          <a href="/usuarios" class="nav-link text-dark font-italic">
            <i class="fas fa-users mr-3 text-warning fa-fw"></i>
            Usuarios
          </a>
        </li>
        <li class="nav-item" onClick={(e) => this.cerrarSesion(e)}>
          <a href="/login" class="nav-link text-dark font-italic">
            <i class="fas fa-door-open mr-3 text-warning fa-fw"></i>
            Salir
          </a>
        </li>
      </ul>
    </div>
    );
  }
}
