import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'


const MySwal = withReactContent(Swal)

const Common = {

    urlIMG: "https://dolcesalato.com.pe/",
    //urlAPI: "http://localhost:38516/api",
    urlAPI: "https://api.dolcesalato.com.pe/api",
    tokenAPI: "faG84*aS1",
    user: 'dolcesalato',
    clave: 'admin2020*',

    notify: (text, type) => {

        MySwal.fire({
            position: 'center',
            icon: type,
            title: text,
            showConfirmButton: false,
            timer: 2000
        })
    },
    notifyValidation: (source) => {

        let htm = `<ul>`

        Object.entries(source).forEach(entry => {
            let key = entry[0];
            let value = entry[1];
            htm += `<li> <span style="color:red">${key}:</span>  ${value[0]} </li>`
        });

        htm += `</ul>`

        MySwal.fire({
            title: 'Validacion de datos',
            icon: 'warning',
            html: htm,
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText:
                '<i class="fa fa-thumbs-up"></i> OK!',
            
        })
    }


}
export default Common;