import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import Common from "./Common";
import axios from "axios";
import { Link } from 'react-router-dom';
import Select from 'react-select';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter} from 'react-bootstrap-table2-filter';



const columns = [{
    dataField: 'nombre',
    text: 'Nombre Producto',
    filter: textFilter(),
  },{
    dataField: 'categoria',
    text: 'Categoria',
    filter: textFilter()
},{
    dataField: 'clasificacion',
    text: 'Clasificacion',
    filter: textFilter()
},{
    dataField: 'popular',
    text: 'Popular',
    filter: textFilter()
},{
    dataField: 'activo',
    text: 'Activo',
    filter: textFilter()
},{
    dataField: 'accion',
    text: 'acción'
}]

const selectOptionsPopular = [
    {value : '0' , label : 'No'},
    {value : '1' , label : 'Si'}
]

const config = {
    headers: { Authorization: Common.tokenAPI}
};

export default class Producto extends Component {

    constructor(props) {
        super(props);

        this.state = { 
            data: [],
            optionsClasificacion: [],
            optionsMedida : [],
            optionsCategoria : [],
            optionsSubCategoria : [],
            selectedValue: '',
        };
    
        this.handleChange = this.handleChange.bind(this);

        this.optionsPopular = [
            {value: '0', label: 'TODOS'},
            {value: '1', label: 'SI'},
            {value: '2', label: 'NO'}
        ];
    }

    async componentDidMount(){

        await axios.get(`${Common.urlAPI}/Clasificacion/Listar`,config).then((response) => {

            let source = response.data.detail.map(item => {
                return {
                    value: item.clasificacionId,
                    label: item.nombre,
                };
            });


            this.setState({optionsClasificacion : source});
        })

        await axios.get(`${Common.urlAPI}/Medida/Listar`,config).then((response) => {

            let source = response.data.detail.map(item => {
                return {
                    value: item.medidaId,
                    label: item.nombre,
                };
            });

            this.setState({optionsMedida : source});
        })

        await axios.get(`${Common.urlAPI}/Categoria/Listar`,config).then((response) => {

            let source = response.data.detail.map(item => {
                return {
                    value: item.categoriaId,
                    label: item.nombre,
                };
            });

            this.setState({optionsCategoria : source});
        })

        await axios.get(`${Common.urlAPI}/SubCategoria/Listar`,config).then((response) => {

            let source = response.data.detail.map(item => {
                return {
                    value: item.subCategoriaId,
                    label: item.nombre,
                };
            });

            this.setState({optionsSubCategoria : source});
        })

        await axios.get(`${Common.urlAPI}/Productos/Listar`,config).then((response) => {

            let source = response.data.detail.map(item => {
                return {
                    nombre: item.nombre,
                    categoria : item.categoria,
                    clasificacion : item.clasificacion,
                    popular : item.popular,
                    activo : item.activo,
                    accion: 
                    (item.clasificacionId === 1)?
                    <Link className="btn btn-outline-warning" to={{pathname : '/editarProducto', state : {productoId : item.productoId}}}>EDITAR</Link>
                    :
                    <Link className="btn btn-outline-warning" to={{pathname : '/detalleProducto', state : {productoId : item.productoId}}}>DETALLE</Link>
                };
            });

            this.setState({data : source});
        })
    }

    handleChange(selectedOption) {

        this.setState({selectedValue: selectedOption.value});
    }

  render() {
    return (
        <div className="container-fluid bg-container">
        <div className="card">
            <h5 className="card-header">Productos</h5>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-3">
                        <button type="button" className="btn btn-warning btn-block" data-toggle="modal" data-target="#staticBackdrop"><i class="fas fa-plus"></i> AGREGAR</button>
                        <div className="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered modal-lg">
                                <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="staticBackdropLabel">AGREGAR PRODUCTO</h5>
                                    <button type="button" class="close" aria-label="Close" data-dismiss="modal">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-md-4 mb-3">
                                                <label>Nombre</label>
                                                <input type="text" name="nombre" className="form-control" />
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <label>Popular</label>
                                                <Select
                                                    placeholder = {"Seleccionar"}
                                                    options = {selectOptionsPopular}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4 mb-3">
                                                <label>Clasificación</label>
                                                <Select
                                                    placeholder = {"Seleccionar"}
                                                    options = {this.state.optionsClasificacion}
                                                />
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <label>Categoria</label>
                                                <Select
                                                    placeholder = {"Seleccionar"}
                                                    options = {this.state.optionsCategoria}
                                                />
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <label>Sub_Categoria</label>
                                                <Select
                                                    placeholder = {"Seleccionar"}
                                                    options = {this.state.optionsSubCategoria}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <label>Descripción</label>
                                                <textarea name="descripcion" className="form-control"   rows="3"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                                    <button type="button" className="btn btn-success">Grabar</button>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br/>
        <div className="card">
            <div className="card-body">
            <BootstrapTable keyField='id' data={ this.state.data } columns={ columns } pagination={ paginationFactory() } filter={ filterFactory() } />
            </div>
        </div>
    </div>
      
    );
  }
}
