import React, { Component } from 'react';
import Common from "./Common";
import axios from "axios";
import Select from 'react-select';

const selectOptionsPopular = [
    {value : '0' , label : 'No'},
    {value : '1' , label : 'Si'}
]

const selectOptionsActivo = [
    {value : '0' , label : 'No'},
    {value : '1' , label : 'Si'}
]

const config = {
    headers: { Authorization: Common.tokenAPI}
};

export default class EditarProducto extends Component {

    constructor(props) {
        super(props);
        
        this.state = { productoId: '', 
                       nombre: '', 
                       precio: '', 
                       cantidad: '', 
                       descripcion: '', 
                       diasEntrega : '',
                       selectedValuePopular: '',
                       selectedValueActivo: '',
                    };

        this.handleChangeActivo = this.handleChangeActivo.bind(this);
        this.handleChangePopular = this.handleChangePopular.bind(this);
    
    }

    componentDidMount(){
        axios.get(`${Common.urlAPI}/Producto/DetalleProducto/${this.props.location.state.productoId}`,config).then((response) => {
    
            let source = response.data.detail.map(item => {
                return {
                    productoId: item.productoId,
                    nombre: item.nombre,
                    imagen : item.imagen,
                    descripcion : item.descripcion,
                    clasificacion : item.clasificacion,
                    cantidad : item.cantidad,
                    precio : item.precio,
                    diasEntrega : item.diasEntrega,
                    popular : item.popular,
                    activo : item.activo
                };
            });
      
            this.setState({
                productoId : source[0].productoId,
                nombre : source[0].nombre,
                precio : source[0].precio,
                cantidad : source[0].cantidad,
                descripcion : source[0].descripcion,
                diasEntrega : source[0].diasEntrega,
                selectedValuePopular : source[0].popular,
                selectedValueActivo : source[0].activo
            });
        })
    }
    
    handleSubmit(e){
        e.preventDefault();
        e.target.reset();
    }

    handleChangePopular(selectedOption) {

        this.setState({selectedValuePopular: selectedOption.value});
    }

    handleChangeActivo(selectedOption) {

        this.setState({selectedValueActivo: selectedOption.value});
    }

    setField (e) {
        this.setState({[e.target.name]: e.target.value})
    }

    grabarProducto(e){

        e.preventDefault();
        const { history } = this.props

         axios.get(`${Common.urlAPI}/Producto/Actualizar?productoId=${this.state.productoId}&nombre=${this.state.nombre}&cantidad=${this.state.cantidad}&precio=${this.state.precio}&descripcion=${this.state.descripcion}&Popular=${this.state.selectedValuePopular}&Activo=${this.state.selectedValueActivo}&DiasEntrega=${this.state.diasEntrega}`,config)
            .then((response) => {

                Common.notify('Cambios guardados', 'success')
                history.push("/");
            })
            .catch(error => {
                if (error.response.status === 400 && error.response.data.message) {
                    Common.notify('Sucedió un error', 'error')
                } else {
                    Common.notifyValidation(error.response.data)
                }

            });
    }

  render() {
    return (
    
    <div className="container-fluid bg-container">
        <div className="row">
            <div className="col-md-12">
            <div className="card">
            <div className="card-header">
                EDITAR PRODUCTO
            </div>
            <div className="card-body">
              
                <form className="needs-validation"  onSubmit={this.handleSubmit.bind(this)}>
                    <div className="form-row">
                        <div className="col-md-4 mb-3">
                            <label>Nombre Producto</label>
                            <input type="text" name="nombre" value={this.state.nombre} onChange={(e)=>this.setField(e)} className="form-control" />
                        </div>
                        <div className="col-md-1 mb-3">
                            <label>Cantidad</label>
                            <input type="number" name="cantidad" value={this.state.cantidad} onChange={(e)=>this.setField(e)} className="form-control" />
                        </div>
                        <div className="col-md-1 mb-3">
                            <label>Precio</label>
                            <input type="number" name="precio" value={this.state.precio} onChange={(e)=>this.setField(e)} className="form-control" />
                        </div>
                        <div className="col-md-2 mb-3">
                            <label>Dias Entrega</label>
                            <input type="number" name="diasEntrega" value={this.state.diasEntrega} onChange={(e)=>this.setField(e)} className="form-control" />
                        </div>
                        <div className="col-md-2 mb-3">
                            <label>Popular</label>
                            <Select
                                placeholder = {"Seleccionar"}
                                options = {selectOptionsPopular}
                                value={selectOptionsPopular.filter(({value}) => value === this.state.selectedValuePopular)}
                                onChange={this.handleChangePopular}
                            />
                        </div>
                        <div className="col-md-2 mb-3">
                            <label>Activo</label>
                            <Select
                                placeholder = {"Seleccionar"}
                                value={selectOptionsActivo.filter(({value}) => value === this.state.selectedValueActivo)}
                                onChange={this.handleChangeActivo}
                                options = {selectOptionsActivo}
                            />
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="col-md-12 mb-3">
                            <label>Descripcion Producto</label>
                            <textarea name="descripcion" className="form-control" value={this.state.descripcion} onChange={(e)=>this.setField(e)}  rows="3"></textarea>
                        </div>
                    </div>
                    <button type="button" className="btn btn-outline-warning" onClick={(e)=>this.grabarProducto(e)}>GRABAR</button>
                </form>
                
            </div>
            </div>
            </div>
        </div>
    </div>
      
    );
  }
}
