import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import Common from "./Common";
import axios from "axios";
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter} from 'react-bootstrap-table2-filter';
import Select from 'react-select';


const columns = [{
    dataField: 'distrito',
    text: 'DISTRITO',
    filter: textFilter(),
  },{
    dataField: 'precio',
    text: 'PRECIO',
    filter: textFilter(),
  },{
    dataField: 'estado',
    text: 'ESTADO',
    filter: textFilter()
},{
    dataField: 'accion',
    text: 'ACCIÓN'
}]

const selectOptionsPopular = [
    {value : 0 , label : 'DESACTIVADO'},
    {value : 1 , label : 'ACTIVO'}
]

const config = {
    headers: { Authorization: Common.tokenAPI}
};

export default class Distritos extends Component {

    constructor(props) {
        super(props);

        this.state = { 
            data: [],
            distritoId : '',
            nombreDistrito : '',
            precio : 0,
            selectedValue: '',
        };
    

        this.handleChange = this.handleChange.bind(this);
    }

    setField (e) {
        this.setState({[e.target.name]: e.target.value})
    }

    handleChange(selectedOption) {

        this.setState({selectedValue: selectedOption.value});
    }

    editDistrict(e, distritoId, nombreDistrito, precio, estado){
       e.preventDefault();
       
       let estadoId = 0;

       if(estado === 'ACTIVO'){
        estadoId = 1;
       }

        this.setState({
            distritoId : distritoId,
            nombreDistrito : nombreDistrito,
            precio : precio,
            selectedValue : estadoId
        })
    }

    updateDistrict(e){
        e.preventDefault();

        this.jsonDistrito = {
            "distritoId" : this.state.distritoId,
            "nombre" : this.state.nombreDistrito,
            "costo" : this.state.precio,
            "activo" : this.state.selectedValue
        }

        axios.post(`${Common.urlAPI}/Distrito/Actualizar`, this.jsonDistrito, config)
        .then((response) => {
            Common.notify('Cambios guardados', 'success')
            this.componentDidMount();
        }).catch((error) => {
            console.log(error.response)
        })
    }

    async componentDidMount(){
        await axios.get(`${Common.urlAPI}/Distrito`,config).then((response) => {

            let source = response.data.detail.map(item => {
                return {
                    distrito : item.nombre,
                    precio : item.costo,
                    estado : item.estado,
                    accion : <button className="btn btn-outline-warning" onClick={(e) => this.editDistrict(e, item.distritoId, item.nombre, item.costo, item.estado)} data-toggle="modal" data-target="#staticBackdrop">EDITAR</button>
                };
            });

            this.setState({data : source});
        })
        
    }


  render() {
    return (
        <div className="container-fluid bg-container">
            <div className="card">
                <h5 className="card-header">Distritos</h5>
                <div className="card-body">

                <div className="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="staticBackdropLabel">ACTUALIZAR DISTRITO</h5>
                            <button type="button" class="close" aria-label="Close" data-dismiss="modal">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-4 mb-3">
                                        <label>Distrito</label>
                                        <input type="text" name="nombreDistrito" value={this.state.nombreDistrito} onChange={(e)=>this.setField(e)} className="form-control" />
                                    </div>
                                    <div className="col-md-4 mb-3">
                                        <label>Precio</label>
                                        <input type="text" name="precio" value={this.state.precio} onChange={(e)=>this.setField(e)} className="form-control" />
                                    </div>
                                    <div className="col-md-4">
                                        <label>Estado</label>
                                        <Select
                                            value={selectOptionsPopular.filter(({value}) => value === this.state.selectedValue)}
                                            onChange={this.handleChange}
                                            placeholder = {"Seleccionar"}
                                            options = {selectOptionsPopular}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                            <button type="button" className="btn btn-success" onClick={(e) => this.updateDistrict(e)}>Actualizar</button>
                        </div>
                        </div>
                    </div>
                </div>

                    <div className="table-responsive table-distrito">
                    <BootstrapTable keyField='id' data={ this.state.data } columns={ columns } pagination={ paginationFactory() } filter={ filterFactory() } />
                    </div>
                </div>
            </div>
        </div>
      
    );
  }
}