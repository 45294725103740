import React, { Component } from 'react';
import { Col, Grid, Row } from 'react-flexbox-grid';
import  NavMenu  from './NavMenu';
import Login from './Login'

export default class Layout extends Component {

  constructor(props) {
    super(props);

    this.state = { 
        flagSesion : localStorage.getItem('sesionAdmin')
    };

}

  render() {
    return (

      (this.state.flagSesion === null) ?
       
       <Login />

      :

      <Grid fluid>
        <Row>
          <Col sm={3}>
              <NavMenu />
          </Col>
          <Col sm={9}>
            {this.props.children}
          </Col>
        </Row>
      </Grid>
    );
  }
}
