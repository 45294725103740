import React, { Component } from 'react';
import Common from "./Common";
import axios from "axios";
import Select from 'react-select';

const selectOptionsActivo = [
    {value : '0' , label : 'No'},
    {value : '1' , label : 'Si'}
]

const config = {
    headers: { Authorization: Common.tokenAPI}
};

export default class EditarProductoDetalle extends Component {

    constructor(props) {
        super(props);
        
        this.state = { productoDetalleId: '',
                       precio: '', 
                       cantidad: '', 
                       selectedValueActivo: '',
                    };

        this.handleChangeActivo = this.handleChangeActivo.bind(this);
    
    }

    componentDidMount(){
        axios.get(`${Common.urlAPI}/DetalleProducto/ListarMedidas/${this.props.location.state.productoDetalleId}`,config).then((response) => {
    
            let source = response.data.detail.map(item => {
                return {
                    productoDetalleId: item.productoDetalleId,
                    cantidad : item.cantidad,
                    precio : item.precio,
                    activo : item.activo
                };
            });
      
            console.log(source[0].activo);

            this.setState({
                productoDetalleId : source[0].productoDetalleId,
                precio : source[0].precio,
                cantidad : source[0].cantidad,
                selectedValueActivo : source[0].activo
            });
        })
    }
    
    handleSubmit(e){
        e.preventDefault();
        e.target.reset();
    }


    handleChangeActivo(selectedOption) {

        this.setState({selectedValueActivo: selectedOption.value});
    }

    setField (e) {
        this.setState({[e.target.name]: e.target.value})
    }

    grabarProducto(e){

        e.preventDefault();
        const { history } = this.props

         axios.get(`${Common.urlAPI}/DetalleProducto/Actualizar?productoDetalleId=${this.state.productoDetalleId}&cantidad=${this.state.cantidad}&precio=${this.state.precio}&Activo=${this.state.selectedValueActivo}`,config)
            .then((response) => {

                Common.notify('Cambios guardados', 'success')
                history.goBack();
            })
            .catch(error => {
                if (error.response.status === 400 && error.response.data.message) {
                    Common.notify('Sucedió un error', 'error')
                } else {
                    Common.notifyValidation(error.response.data)
                }

            });
    }

  render() {
    return (
    
    <div className="container-fluid bg-container">
        <div className="row">
            <div className="col-md-12">
            <div className="card">
            <div className="card-header">
                EDITAR MEDIDA PRODUCTO
            </div>
            <div className="card-body">
              
                <form className="needs-validation"  onSubmit={this.handleSubmit.bind(this)}>
                    <div className="form-row">
                        <div className="col-md-2 mb-3">
                            <label>Cantidad</label>
                            <input type="text" name="cantidad" value={this.state.cantidad} onChange={(e)=>this.setField(e)} className="form-control" />
                        </div>
                        <div className="col-md-2 mb-3">
                            <label>Precio</label>
                            <input type="number" name="precio" value={this.state.precio} onChange={(e)=>this.setField(e)} className="form-control" />
                        </div>
                        <div className="col-md-2 mb-3">
                            <label>Activo</label>
                            <Select
                                placeholder = {"Seleccionar"}
                                value={selectOptionsActivo.filter(({value}) => value === this.state.selectedValueActivo)}
                                onChange={this.handleChangeActivo}
                                options = {selectOptionsActivo}
                            />
                        </div>
                    </div>
                    <button type="button" className="btn btn-outline-warning" onClick={(e)=>this.grabarProducto(e)}>GRABAR</button>
                </form>
                
            </div>
            </div>
            </div>
        </div>
    </div>
      
    );
  }
}
