import React, {Component} from 'react';
import Common from "./Common";


export default class Login extends Component {

    constructor(props) {
        super(props);
        
        this.state = { 
            usuario: null,
            contrasenia: null,
            flagSesion : localStorage.getItem('sesionAdmin')
        };
        
    }


    setField (e) {
    
        this.setState({[e.target.name]: e.target.value});
    }


    Login(e){

        e.preventDefault();

        let usuario = this.state.usuario;
        let contrasenia = this.state.contrasenia;

       if(usuario === Common.user && contrasenia === Common.clave){

        localStorage.setItem('sesionAdmin',1);

        window.location.reload();

       }else{
         Common.notify('Usuario o contraseña incorrectos', 'error')
       }

    }

    render(){
        return(
            <div className="container-fluid bordes-ds">
            <section className="col-md-12 col-lg-12 col-xl-12">
            <div className="row login-responsive">
                <div className="col-md-4 mx-auto">
                <div id="first">
                    <div className="myform form ">
                        <div className="logo mb-3">
                            <div className="col-md-12 text-center">
                                <h1>Iniciar Sesión</h1>
                            </div>
                        </div>
                        <form>
                            <div className="form-group">
                                <label>Usuario</label>
                                <input type="email" name="usuario"  className="form-control" onChange={(e)=>this.setField(e)} />
                            </div>
                            <div className="form-group">
                                <label>Contraseña</label>
                                <input type="password" name="contrasenia" className="form-control" onChange={(e)=>this.setField(e)} />
                            </div>
                            <div className="col-md-12 text-center ">
                                <button  className=" btn btn-block mybtn btn-dolcesalato tx-tfm" onClick={(e) =>this.Login(e)}>INICIAR SESIÓN</button>
                            </div>
                        </form>
                    
                    </div>
                </div>
            </div>
        </div>
        </section>
        </div>
         
        )
    }
}