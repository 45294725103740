import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import Common from "./Common";
import axios from "axios";
import { Link } from 'react-router-dom';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter} from 'react-bootstrap-table2-filter';
import Select from 'react-select';


const columns = [{
    dataField: 'nroPedido',
    text: 'NRO. PEDIDO',
    filter: textFilter(),
  },{
    dataField: 'nombre',
    text: 'NOMBRE USUARIO',
    filter: textFilter(),
  },{
    dataField: 'celular',
    text: 'CELULAR',
    filter: textFilter(),
  },{
    dataField: 'metodoEnvio',
    text: 'METODO ENVIO',
    filter: textFilter()
},{
    dataField: 'distrito',
    text: 'DISTRITO',
    filter: textFilter()
},{
    dataField: 'direccionEnvio',
    text: 'DIRECCIÓN ENVIO',
    filter: textFilter()
},{
    dataField: 'referencia',
    text: 'REFERENCIA',
    filter: textFilter()
},{
    dataField: 'fechaEntrega',
    text: 'FECHA ENTREGA',
    filter: textFilter()
},{
    dataField: 'tipoComprobante',
    text: 'TIPO COMPROBANTE',
    filter: textFilter()
},{
    dataField: 'nroDocumento',
    text: 'NRO DOCUMENTO',
    filter: textFilter()
},{
    dataField: 'nombresCompletos',
    text: 'NOMBRES COMPLETOS',
    filter: textFilter()
},{
    dataField: 'razonSocial',
    text: 'RAZON SOCIAL',
    filter: textFilter()
},{
    dataField: 'direccionFiscal',
    text: 'DIRECCIÓN FISCAL',
    filter: textFilter()
},{
    dataField: 'tipoPago',
    text: 'TIPO PAGO',
    filter: textFilter()
},{
    dataField: 'dedicatoria',
    text: 'DEDICATORIA',
    filter: textFilter()
},{
    dataField: 'subTotal',
    text: 'SUB TOTAL',
    filter: textFilter()
},{
    dataField: 'descuento',
    text: 'DESCUENTO',
    filter: textFilter()
},{
    dataField: 'delivery',
    text: 'DELIVERY',
    filter: textFilter()
},{
    dataField: 'total',
    text: 'TOTAL',
    filter: textFilter()
},{
    dataField: 'estadoPedido',
    text: 'ESTADO PEDIDO',
    filter: textFilter()
},{
    dataField: 'fechaRegistro',
    text: 'FECHA REGISTRO',
    filter: textFilter()
},{
    dataField: 'accion',
    text: 'ACCIÓN'
},{
    dataField: 'accion2',
    text: 'ACCIÓN'
}]


const config = {
    headers: { Authorization: Common.tokenAPI}
};

export default class Producto extends Component {

    constructor(props) {
        super(props);

        this.state = { 
            data: [],
            optionsEstadoPedido : [],
            pedidoId : '',
            selectedValue : ''
        };

        this.handleChange = this.handleChange.bind(this);
    }

    convert(str) {
        var date = new Date(str),
          mnth = ("0" + (date.getMonth() + 1)).slice(-2),
          day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

    setField (e, pedidoId) {

        e.preventDefault();

        this.setState({pedidoId : pedidoId})
    }

    handleChange(selectedOption) {

        this.setState({selectedValue: selectedOption.value});
    }

    updateStateOrder(e){
        e.preventDefault();

        this.jsonEstadoPedido = {
           "pedidoId" : this.state.pedidoId,
           "estadoPedido" : this.state.selectedValue
        }

        axios.post(`${Common.urlAPI}/EstadoPedido/Actualizar`, this.jsonEstadoPedido, config)
        .then((response) => {
            Common.notify('Cambios guardados', 'success')
            this.componentDidMount();
        }).catch((error) => {
            console.log(error.response)
        })
    }

    async componentDidMount(){

        await axios.get(`${Common.urlAPI}/Pedido/ListarTodos`,config).then((response) => {

            let source = response.data.detail.map(item => {
                return {
                    pedidoId : item.pedidoId,
                    nroPedido : 'P00'+item.pedidoId,
                    nombre : item.nombre,
                    celular : item.celular,
                    metodoEnvio : item.metodoEnvioNombre,
                    distrito : item.distrito,
                    direccionEnvio : item.direccionEnvio,
                    referencia : item.referencia,
                    fechaEntrega : this.convert(new Date(item.fechaEntrega)),
                    tipoComprobante : item.tipoComprobanteNombre,
                    nroDocumento : item.nroDocumento,
                    nombresCompletos : item.nombresCompletos,
                    razonSocial : item.razonSocial,
                    direccionFiscal : item.direccionFiscal,
                    tipoPago : item.tipoPagoNombre,
                    dedicatoria : item.dedicatoria,
                    subTotal : item.subTotal,
                    descuento : item.descuento,
                    delivery : item.delivery,
                    total : item.total,
                    estadoPedido : item.estadoPedidoNombre,
                    fechaRegistro : this.convert(new Date(item.fechaRegistro)),
                    accion : <button type="button"  className="btn btn-outline-warning" data-toggle="modal" data-target="#staticBackdrop" onClick={(e) => this.setField(e, item.pedidoId)}>ACTUALIZAR ESTADO</button>,
                    accion2 : <Link className="btn btn-outline-warning" to={{pathname : '/detallePedido', state : {pedidoId : item.pedidoId}}}>DETALLE</Link>
                };
            });

            this.setState({data : source});
        })

        await axios.get(`${Common.urlAPI}/EstadoPedido/Listar`,config).then((response) => {

            let source = response.data.detail.map(item => {
                return {
                    value: item.estadoPedidoId,
                    label: item.nombre,
                };
            });

            this.setState({optionsEstadoPedido : source});
        })
    }


  render() {
    return (
        <div className="container-fluid bg-container">
            <div className="card">
                <h5 className="card-header">Pedidos</h5>
                <div className="card-body">
                <div className="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered modal-md">
                                <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="staticBackdropLabel">ACTUALIZAR ESTADO</h5>
                                    <button type="button" class="close" aria-label="Close" data-dismiss="modal">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="container-fluid">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <label>Estado Pedido</label>
                                                <Select
                                                    value={this.state.optionsEstadoPedido.filter(({value}) => value === this.state.selectedValue)}
                                                    onChange={this.handleChange}
                                                    placeholder = {"Seleccionar"}
                                                    options = {this.state.optionsEstadoPedido}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Cerrar</button>
                                    <button type="button" className="btn btn-success" onClick={(e) => this.updateStateOrder(e)}>Actualizar</button>
                                </div>
                                </div>
                            </div>
                        </div>

                    <div className="table-responsive table-pedido">
                    <BootstrapTable keyField='id' data={ this.state.data } columns={ columns } pagination={ paginationFactory() } filter={ filterFactory() } />
                    </div>
                </div>
            </div>
        </div>
      
    );
  }
}