import React, { Component } from 'react';
import { Route } from 'react-router';
import Layout from './components/Layout';
import Producto from './components/Producto';
import EditarProducto from './components/EditarProducto'
import DetalleProducto from './components/DetalleProducto'
import EditarDetalleProducto from './components/EditarDetalleProducto'
import Pedido from './components/Pedido'
import Distritos from './components/Distritos'
import Usuarios from './components/Usuarios';
import DetallePedido from './components/DetallePedido';
import Login from './components/Login';


export default class App extends Component {

  render() {
    return (
      <Layout>
        <Route exact path='/' component={Producto} />
        <Route  path='/editarProducto' component={EditarProducto} />
        <Route exact path='/producto' component={Producto} />
        <Route exact path='/detalleProducto' component={DetalleProducto} />
        <Route exact path='/editarDetalleProducto' component={EditarDetalleProducto} />
        <Route exact path='/pedido' component={Pedido} />
        <Route exact path='/distritos' component={Distritos} />
        <Route exact path='/usuarios' component={Usuarios} />
        <Route exact path='/detallePedido' component={DetallePedido} />
        <Route exact path='/login' component={Login} />
      </Layout>
    );
  }
}
