import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import Common from "./Common";
import axios from "axios";
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory from 'react-bootstrap-table2-filter';


const columns = [{
    dataField: 'nombreProducto',
    text: 'NOMBRE PRODUCTO'
  },{
    dataField: 'sabor',
    text: 'SABOR'
  },{
    dataField: 'relleno1',
    text: 'RELLENO 1',
},{
    dataField: 'relleno2',
    text: 'RELLENO 2'
},{
    dataField: 'cantidad',
    text: 'UNIDAD/PORCIONES/CAJA'
},{
    dataField: 'cantidadPedido',
    text: 'CANTIDAD PEDIDO'
}]


const config = {
    headers: { Authorization: Common.tokenAPI}
};

export default class DetallePedido extends Component {

    constructor(props) {
        super(props);

        this.state = { 
            data: [],
        };
    
    }


    async componentDidMount(){
        await axios.get(`${Common.urlAPI}/DetallePedido/Listar/${this.props.location.state.pedidoId}`,config).then((response) => {

            let source = response.data.detail.map(item => {
                return {
                    nombreProducto : item.nombreProducto,
                    sabor : item.sabor,
                    relleno1 : item.relleno1,
                    cantidad : item.cantidad,
                    cantidadPedido : item.cantidadPedido
                };
            });

            this.setState({data : source});
        })
        
    }


  render() {
    return (
        <div className="container-fluid bg-container">
            <div className="card">
                <h5 className="card-header">Detalle Pedido</h5>
                <div className="card-body">
                    <div className="table-responsive table-detallepedido">
                    <BootstrapTable keyField='id' data={ this.state.data } columns={ columns } pagination={ paginationFactory() } filter={ filterFactory() } />
                    </div>
                </div>
            </div>
        </div>
      
    );
  }
}