import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import Common from "./Common";
import axios from "axios";
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter} from 'react-bootstrap-table2-filter';


const columns = [{
    dataField: 'nombre',
    text: 'NOMBRE',
    filter: textFilter(),
  },{
    dataField: 'apellidos',
    text: 'APELLIDOS',
    filter: textFilter(),
  },{
    dataField: 'celular',
    text: 'CELULAR',
    filter: textFilter()
},{
    dataField: 'email',
    text: 'EMAIL',
    filter: textFilter(),
},{
    dataField: 'fechaCumpleano',
    text: 'FECHA CUMPLEAÑOS',
    filter: textFilter(),
},{
    dataField: 'fechaRegistro',
    text: 'FECHA REGISTRO',
    filter: textFilter(),
}]


const config = {
    headers: { Authorization: Common.tokenAPI}
};

export default class Usuarios extends Component {

    constructor(props) {
        super(props);

        this.state = { 
            data: [],
        };
    
    }

    convert(str) {
        var date = new Date(str),
          mnth = ("0" + (date.getMonth() + 1)).slice(-2),
          day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), mnth, day].join("-");
    }

    async componentDidMount(){
        await axios.get(`${Common.urlAPI}/Persona/ListarUsuarios`,config).then((response) => {

            let source = response.data.detail.map(item => {
                return {
                    nombre : item.nombre,
                    apellidos : item.apellidos,
                    celular : item.celular,
                    email : item.email,
                    fechaCumpleano : this.convert(new Date(item.fechaCumpleano)),
                    fechaRegistro : this.convert(new Date(item.fechaRegistro))
                };
            });

            this.setState({data : source});
        })
        
    }


  render() {
    return (
        <div className="container-fluid bg-container">
            <div className="card">
                <h5 className="card-header">Usuarios</h5>
                <div className="card-body">
                    <div className="table-responsive table-usuarios">
                    <BootstrapTable keyField='id' data={ this.state.data } columns={ columns } pagination={ paginationFactory() } filter={ filterFactory() } />
                    </div>
                </div>
            </div>
        </div>
      
    );
  }
}